import BuyCard from "./BuyCard";
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as Scroll } from 'react-scroll';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import {motion} from "framer-motion"
import {CssBaseline } from '@mui/material';
import { baseUrl } from '../../utils/basic';


function Landing() {

  let navigate = useNavigate();

  const goToAuctions = () =>{
    navigate("/auctions");
  }

  const goToLogin = () =>{
    navigate("/login");
  }

  const logoUrl = `${baseUrl}/images/lfg-logo-full.png`;

  const kind = [
    {
      title: 'Sell',
      description:
        "List items to be auctioned. Set start and end times of auction, bid price etc.",
      imageUrl: `${baseUrl}/images/africanart.jpg`,
      time: 1500,
    },
    {
      title: 'Buy',
      description:
        'Bid on auction items. Explore our catalog. Communicate using our mail app.',
      imageUrl: `${baseUrl}/images/landscape.jpg`,
      time: 1500,
    },
  ];

  return (
    <div  style={{     minHeight: '100vh', width: '100%',
      backgroundImage: `url("${baseUrl}/images/background.png")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', }}>
      
      <CssBaseline />
      <motion.div style={{display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontFamily: 'Futura'}} id="header"
    
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
      <AppBar style={{background: 'none', }} elevation={0}>
        <Toolbar style={{width: '80%',
    margin: '0 auto', }}>
          {
          <img src={logoUrl} alt="logo" style={{width: 200}}></img>
          }
          <IconButton onClick={goToLogin}>
            <ExitToAppIcon style={{    color: '#fff',
    fontSize: '2rem', }} />
          </IconButton>
        </Toolbar>
      </AppBar>

            <div style={{     textAlign: 'center',}} >
              <h1 style={{    flexGrow: '1', fontSize: '4.5rem',
        background: "-webkit-linear-gradient(45deg, #00C9FF 0%, #92FE9D 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent" }}  > 
        <div style={{     color: '#fff',
        fontSize: '4.5rem', }}>
            LFG Auctions
            </div>
          </h1>
          
          <Scroll to="DOWN" smooth={true}>
            <IconButton>
              <ExpandMoreIcon style={{     color: '#b5efff', fontSize: '4rem', }} />
            </IconButton>
          </Scroll>

        </div>
        </motion.div>
        
        <div style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        }} 
        
        id="DOWN">
          <div onClick={goToAuctions}>
        <BuyCard kind={kind[1]} /></div>
        <div onClick={goToLogin}>
        <BuyCard kind={kind[0]}  />
        </div>
      </div>
    </div>
  );
}

export default Landing

/*
https://unsplash.com/photos/a-table-topped-with-lots-of-different-types-of-items-28wfoTHdSc8
https://unsplash.com/photos/two-white-and-blue-ceramic-dinnerware-okoHdy22tZw
https://unsplash.com/photos/body-of-water-surrounded-by-trees-NRQV-hBF10M
*/
